import Clear from '@/layouts/Clear.vue';

export default {
  path: '/dashboard',
  component: Clear,
  children: [
    {
      path: '',
      name: 'dashboard',
      component: () => import('@/views/Dashboard.vue'),
    }
  ],
};
