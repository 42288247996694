import Vue from 'vue';

import { ValidationProvider } from 'vee-validate';
import AppHeader from '@/components/AppHeader.vue';
import NoContent from '@/components/NoContent.vue';
import AppInput from '@/components/inputs/AppInput.vue';
import AppEditor from '@/components/inputs/AppEditor.vue';
import AppFilePicker from '@/components/inputs/AppFilePicker.vue';

Vue.component('app-input', AppInput);
Vue.component('app-header', AppHeader);
Vue.component('no-content', NoContent);
Vue.component('app-editor', AppEditor);
Vue.component('app-file-picker', AppFilePicker);
Vue.component('ValidationProvider', ValidationProvider);
