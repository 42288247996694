export default [
  // {
  //   icon: 'home',
  //   label: 'Dashboard',
  //   routeName: 'dashboard',
  // },
  {
    icon: 'clipboard',
    label: 'Consultas',
    routeName: 'schedules.index',
  },
  {
    icon: 'user-cog',
    label: 'Administradores',
    routeName: 'admins.index',
  },
  {
    icon: 'clinic-medical',
    label: 'Clínicas',
    routeName: 'clinics.index',
  },
  {
    icon: 'user-md',
    label: 'Médicos',
    routeName: 'doctors.index',
  },
  {
    icon: 'user-injured',
    label: 'Pacientes',
    routeName: 'patients.index',
  },
  {
    icon: 'stethoscope',
    label: 'Especialidades / Profissões',
    routeName: 'specialties.index',
  },
  {
    icon: 'heartbeat',
    label: 'Subespecialidades',
    routeName: 'subspecialties.index',
  },
  {
    icon: 'file-contract',
    label: 'Termos de uso',
    routeName: 'terms.index',
  },
  // {
  //   icon: 'cogs',
  //   label: 'Configurações',
  //   routeName: 'settings.index',
  // },
  {
    icon: 'mobile-alt',
    label: 'Aplicativos Customizados',
    routeName: 'custom-app-settings.index',
  },
];
