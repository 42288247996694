import Clear from '@/layouts/Clear.vue';

export default {
  path: '/specialties',
  component: Clear,
  children: [
    {
      path: '',
      name: 'specialties.index',
      component: () => import('@/views/Specialty/SpecialtyList.vue'),
    },
    {
      path: ':id?',
      name: 'specialties.save',
      component: () => import('@/views/Specialty/Specialty.vue'),
    },
  ],
};
