import Clear from '@/layouts/Clear.vue';

export default {
  path: '/schedules',
  component: Clear,
  children: [
    {
      path: '',
      name: 'schedules.index',
      component: () => import('@/views/Schedule/ScheduleList.vue'),
    },
    {
      path: ':id?',
      name: 'schedules.save',
      component: () => import('@/views/Schedule/Schedule.vue'),
    },
  ],
};
