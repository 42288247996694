import Clear from '@/layouts/Clear.vue';

export default {
  path: '/terms',
  component: Clear,
  children: [
    {
      path: '',
      name: 'terms.index',
      component: () => import('@/views/Term/TermList.vue'),
    },
    {
      path: ':id?',
      name: 'terms.save',
      component: () => import('@/views/Term/Term.vue'),
    },
    {
      path: ':id?',
      name: 'base-term.save',
      component: () => import('@/views/Term/BaseTerm.vue'),
    },
  ],
};
