import Vue from 'vue';

export default Vue.filter('number', (value) => {
  return value
    ? value.toLocaleString('pt-BR', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })
    : 0;
});
