<template>
  <div class="dashboard-layout">
    <section class="menu-container">
      <b-sidebar position="static" type="is-white" open>
        <div class="logo-ctn">
          <div>
            <img :src="require('@/assets/img/logo.png')" alt="" srcset="" />
          </div>
        </div>
        <b-menu :activable="false">
          <b-menu-list label="Menu">
            <router-link
              tag="div"
              v-for="(menu, index) in menus"
              :key="index"
              :to="{ name: menu.routeName }"
              :exact="menu.routeName === 'index'"
            >
              <b-menu-item :icon="menu.icon" :label="menu.label"></b-menu-item>
            </router-link>
          </b-menu-list>
          <b-menu-list label="Ações">
            <b-menu-item
              @click="onLogoutClick()"
              icon="sign-out-alt"
              label="Sair"
            ></b-menu-item>
          </b-menu-list>
        </b-menu>
      </b-sidebar>
    </section>
    <section class="router-container custom-scroll">
      <router-view></router-view>
    </section>
  </div>
</template>

<script>
import MENUS from '@/constants/menu.constant';
import { AUTH_LOGOUT } from '@/store/actions/auth';

export default {
  data: () => ({
    menus: MENUS,
  }),
  methods: {
    onLogoutClick() {
      this.$buefy.dialog.confirm({
        message: 'Deseja realmente sair?',
        onConfirm: () => this.logout(),
      });
    },
    logout() {
      this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push('/login'));
    },
  },
  beforeMount() {},
};
</script>

<style lang="scss">
div.dashboard-layout {
  display: flex;
  height: 100vh;
  background: var(--color-secondary);

  section.menu-container {
    height: 100vh;
    background: var(--color-secondary);

    .sidebar-content {
      height: 100vh;
      box-shadow: 0 2px 16px -4px rgba(0, 0, 0, 0.5);
      background: var(--color-secondary);

      .logo-ctn {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f9f9f9;
        margin-bottom: 15px;
        padding: 1.8rem 0;

        div {
          width: 140px;
        }
      }

      .fixed-menu {
        background: #f9f9f9;
      }

      .menu-label {
        padding-left: 0.6rem;
        color: #fff;
      }

      div.router-link-active {
        > li a {
          background: #fff;
          color: #000;
          font-weight: 600;
        }
      }

      div a:hover {
        background: var(--color-primary);
      }

      li a {
        padding: 0.8rem;
        color: #fff;
        span:first-child {
          margin-right: 0.5rem;
        }
      }
    }
  }

  section.router-container {
    flex: 1;
    height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
    background: #f9f9f9;
    padding: 1.4rem;
  }
}

@media only screen and (max-width: 1440px) {
  div.dashboard-layout {
    section.menu-container {
      direction: rtl;

      overflow: auto;
      .menu-label {
        direction: ltr;
      }
      .menu-list {
        direction: ltr;
      }
      &::-webkit-scrollbar-thumb {
        background: var(--color-primary);
      }

      &::-webkit-scrollbar {
        width: 8px;

        background-color: #f7f5f5;
      }

      .sidebar-content {
        height: 110vh;

        .pin {
          top: 2vh;
        }
      }
    }
  }
}
</style>
