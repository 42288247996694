import Clear from '@/layouts/Clear.vue';

export default {
  path: '/custom-app-settings',
  component: Clear,
  children: [
    {
      path: '',
      name: 'custom-app-settings.index',
      component: () =>
        import('@/views/CustomAppSettings/CustomAppSettingsList.vue'),
    },
    {
      path: ':id?',
      name: 'custom-app-settings.form',
      component: () =>
        import('@/views/CustomAppSettings/CustomAppSettingForm.vue'),
    },
  ],
};
